<template>
	<div class="user-mian">
		<!-- 机构子集列表 -->
		<div class="user-h2-text">
			<div class="user-h2-texts">
				<div>机构</div>
				<div style="color: orangered;margin: 0px 5px;">{{hos_name}}</div>
				<div>的子级列表</div>
			</div>
			<div>
				<Button type="primary" @click="returnUser">返回上一级</Button>
			</div>
		</div>
		<!-- 收索 -->
		<div class="user-search">
			<div>
				<Input v-model="searchData.hos_name" clearable placeholder="请输入机构名称..." style="width: 300px" />
			</div>
			<div>
				<Button type="primary" @click="searchCk">搜索</Button>
			</div>
		</div>
		<!-- 添加按钮 -->
		<div class="user-add">
			<div>
				<Button type="primary" @click="doctorAdd">添加合作机构</Button>
			</div>
		</div>
		<!-- 表格 -->
		<div class="user-table">
			<Table :columns="columns" border :data="data" width="100rem">
				<template slot="action" slot-scope="{ row }">
					<Button type="primary" size="small" style="margin-right: 5px" @click="equipmentEidtShow(row.id)">编辑</Button>
					<Button type="primary" size="small" style="margin-right: 5px" @click="equipmentCk(row)">合作体检机构</Button>
					<Button type="error" size="small" @click="mechaCk(row)">设备</Button>
				</template>
				<template slot-scope="{ row }" slot="speaces">
					<div>{{row.speaces}}</div>
				</template>
			</Table>
		</div>
		<!-- 分页 -->
		<div class="user-page">
			<Page :total="total" show-elevator :show-total="true" :current="page" @on-change="onChange" :page-size="pagesize"/>
		</div>
		<!-- 弹出层 -->
		<Modal v-model="doctorPop" width="888px">
			<p slot="header" style="color:#2D8CF0;text-align:center">
				<Icon type="ios-information-circle"></Icon>
				<span>添加机构</span>
			</p>
			<div class="pop">
				<Form :label-width="100">
					<FormItem label="上级机构">
						<Input v-model="hos_name" disabled />
					</FormItem>
					<FormItem label="手机号">
						<Input placeholder="请输入手机号" v-model="formData.phone"/>
					</FormItem>
					<FormItem label="机构名称">
						<Input placeholder="请输入机构名称" v-model="formData.hos_name"/>
					</FormItem>
					<FormItem label="机构等级">
						<Input placeholder="请输入机构所属等级" v-model="formData.type"/>
					</FormItem>
					<FormItem label="所在区域">
						<Cascader :data="dataCascader" v-model="formData.speaces"></Cascader>
					</FormItem>
					<FormItem label="详细地址">
						<Input placeholder="请输入机构详细地址" v-model="formData.address" />
					</FormItem>
					<FormItem label="机构Logo">
						<Upload action="//jsonplaceholder.typicode.com/posts/">
							<Button icon="ios-cloud-upload-outline">上传Logo</Button>
						</Upload>
					</FormItem>
				</Form>
			</div>
			<div slot="footer">
				<Button type="error" size="small" @click="doctorPop = false">取消</Button>
				<Button type="primary" size="small" @click="equipmentSand">确认</Button>
			</div>
		</Modal>
		
		<!-- 编辑弹出层 -->
		<Modal v-model="doctorEidtPop" width="888px">
			<p slot="header" style="color:#ED4014;text-align:center">
				<Icon type="ios-information-circle"></Icon>
				<span>编辑机构</span>
			</p>
			<div class="pop">
				<Form :label-width="100">
					<FormItem label="手机号">
						<Input placeholder="请输入手机号" v-model="formEidtData.phone"/>
					</FormItem>
					<FormItem label="机构名称">
						<Input placeholder="请输入机构名称" v-model="formEidtData.hos_name"/>
					</FormItem>
					<FormItem label="机构等级">
						<Input placeholder="请输入机构所属等级" v-model="formEidtData.type"/>
					</FormItem>
					<FormItem label="所在区域">
						<Cascader :data="dataCascader" v-model="formEidtData.speaces"></Cascader>
					</FormItem>
					<FormItem label="详细地址">
						<Input placeholder="请输入机构详细地址" v-model="formEidtData.address" />
					</FormItem>
					<FormItem label="机构Logo">
						<Upload action="//jsonplaceholder.typicode.com/posts/">
							<Button icon="ios-cloud-upload-outline">上传Logo</Button>
						</Upload>
					</FormItem>
				</Form>
			</div>
			<div slot="footer">
				<Button type="error" size="small" @click="doctorEidtPop = false">取消</Button>
				<Button type="primary" size="small" @click="equipmentEidtSand">确认</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	export default {
		created() {
			this.id = this.$route.query.id;
			this.hos_name = this.$route.query.name;
			this.equipmentList();
		},
		data() {
			return {
				id:"",
				hos_name:"",
				doctorEidtPop:false,
				//地址选择
				dataCascader: [{
					value: 'beijing',
					label: '北京',
					children: [{
							value: '故宫',
							label: '故宫'
						},
						{
							value: '天坛',
							label: '天坛'
						},
						{
							value: '王府井',
							label: '王府井'
						}
					]
				}, {
					value: '福建省',
					label: '福建省',
					children: [{
							value: '福州市',
							label: '福州市',
							children: [{
								value: '台江区',
								label: '台江区',
							},
							{
								value: '仓山区',
								label: '仓山区',
							},
							{
								value: '晋安区',
								label: '晋安区',
							}
							]
						},
						{
							value: '泉州市',
							label: '泉州市',
							children: [{
									value: '拙政园',
									label: '拙政园',
								},
								{
									value: '狮子林',
									label: '狮子林',
								}
							]
						}
					],
				}],
				total: 0,
				page: 1, //当前码
				pagesize: 8, //每页显示多少条
				doctorPop: false,
				searchData: {
					hos_name: ""
				},
				formData:{
					phone:"",
					hos_name:"",
					type:"",
					speaces:[],
					address:"",
					pid:"",
				},
				formEidtData:{
					id:"",
					phone:"",
					hos_name:"",
					type:"",
					speaces:[],
					address:"",
				},
				columns: [{
						title: '编号',
						key: 'id',
					},
					{
						title: '全称',
						key: 'hos_name',
					},
					{
						title: '医院等级',
						key: 'type',
					},
					{
						title: '所在区域',
						key: 'speaces',
						slot: 'speaces',
					},
					{
						title: '详细地址',
						key: 'address',
					},
					{
						title: '联系方式',
						key: 'phone',
					},
					{
						title: '操作',
						slot: 'action',
						width: 350,
						align: 'center',
					}
				],
				data: []
			}
		},
		methods: {
			//添加医生
			doctorAdd() {
				this.doctorPop = true;
			},
			//查看合作机构
			equipmentCk(row){
				this.$router.push({
					path: '/cooperation_list',
					query: {
						id: row.id,
						name:row.hos_name
					}
				})
			},
			//查看设备列表
			mechaCk(row){
				console.log(row)
				this.$router.push({path:'/equipment_mechanism',query: {id:'1'}})
			},
			//查看子集
			chiEquipment(row){
				this.$router.push({
					path: '/childEquipment_list',
					query: {
						id: row.id,
						name:row.hos_name
					}
				})
			},
			//搜索接口
			searchCk(){
				this.page = 1;
				this.equipmentList();
			},
			// 分页页码获取
			onChange: function(currentPage) {
				this.page = currentPage;
				this.equipmentList();
			},
			equipmentList(){
				let data = {
					page: this.page,
					pagesize: this.pagesize,
					hos_name:this.searchData.hos_name,
					id:this.id,
				};
				let url = this.httpsUrl + 'equipmentController/chiEquipmentList';
				this.$http.post(url, data, true).then(result => {
					console.log(result.data.data)
					this.data = result.data.data
					this.total = result.data.count
				});
			},
			//添加机构
			equipmentSand(){
				if(this.formData.phone.length > 11) {
					this.$Message.info('手机号不能小于11位');
					return;
				}
				if(this.formData.hos_name == '') {
					this.$Message.info('机构名称必选');
					return;
				}
				if(this.formData.type == '') {
					this.$Message.info('机构等级必选');
					return;
				}
				if(this.formData.speaces == '') {
					this.$Message.info('机构所在区域必选');
					return;
				}
				if(this.formData.address == '') {
					this.$Message.info('机构详细地址必选');
					return;
				}
				let data = this.formData;
				data.pid = this.id
				let url = this.httpsUrl + 'equipmentController/addEquipmentSand';
				this.$http.post(url, data, true).then(result => {
					if(result.data.code == 200){
						this.$Message.info(result.data.msg);
						this.doctorPop = false;
						this.equipmentList();
						return;
					}
					this.$Message.info(result.data.msg);
				});
			},
			//编辑查看
			equipmentEidtShow(id){
				this.doctorEidtPop = true;
				let data = {
					id:id
				}
				let url = this.httpsUrl + 'equipmentController/eidtEquipmentShow';
				this.$http.post(url, data, true).then(result => {
					this.formEidtData = result.data.data
				});
			},
			//编辑发送
			equipmentEidtSand(){
				if(this.formEidtData.phone.length > 11) {
					this.$Message.info('手机号不能小于11位');
					return;
				}
				if(this.formEidtData.hos_name == '') {
					this.$Message.info('机构名称必选');
					return;
				}
				if(this.formEidtData.type == '') {
					this.$Message.info('机构等级必选');
					return;
				}
				if(this.formEidtData.speaces == '') {
					this.$Message.info('机构所在区域必选');
					return;
				}
				if(this.formEidtData.address == '') {
					this.$Message.info('机构详细地址必选');
					return;
				}
				let data = this.formEidtData;
				let url = this.httpsUrl + 'equipmentController/eidtEquipmentSand';
				this.$http.post(url, data, true).then(result => {
					if(result.data.code == 200){
						this.$Message.info(result.data.msg);
						this.doctorEidtPop = false;
						this.equipmentList();
						return;
					}
					this.$Message.info(result.data.msg);
				});
			},
			returnUser(){
				this.$router.go(-1)
			},
		}
	}
</script>

<style>
	.user-mian {
		display: flex;
		flex-flow: column nowrap;
		background: #fff;
		padding: 20px 10px;
		border-radius: 10px;
	}

	.user-search {
		display: flex;
		flex-flow: row;
	}

	.user-search>div:last-of-type {
		margin-left: 10px;
	}

	.user-add {
		display: flex;
		flex-flow: row;
		height: 50px;
		align-items: flex-end;
	}

	.user-table {
		margin-top: 10px;
	}

	.user-page {
		margin-top: 20px;
	}
	.user-h2-text {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		height: 50px;
		align-items: center;
	}
	.user-h2-texts {
		display: flex;
		flex-flow: row;
		font-size: 20px;
		margin-bottom: 10px;
	}
</style>
