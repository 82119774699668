import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/iview.js'

// 接口部分
import http from './http/http.js';
// axios 拦截器
import './http/axios';
Vue.prototype.httpsUrl = "http://register.12590.com/index.php/";
// Vue.prototype.httpsUrl = "http://tp6.com/index.php/";
//文件上传服务器地址
//Vue.prototype.updateUrls = "http://45092918-1282812597414035.test.functioncompute.com/api/upload/UploadImage";
// 对请求方式 进行全局注册
Vue.prototype.$http = http;

Vue.config.productionTip = false,

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
