<template>
	<div class="user-mian">
		<!-- 用户签约医生详情 -->
		<div class="user-h2-text">
			<div class="user-h2-texts">
				<div>用户</div>
				<div style="color: orangered;margin: 0px 5px;">{{name}}</div>
				<div>签约医生</div>
			</div>
		</div>
		<!-- 收索 -->
		<div class="user-search">
			<div>
				<Input v-model="searchData.realname" clearable placeholder="请输入医生名称..." style="width: 300px" />
			</div>
			<div style="margin-left: 10px;">
				<Select placeholder="请选择医生类型..." clearable v-model="searchData.doctor_type" style="width: 200px">
					<Option v-for="item in type" :value="item.value" :key="item.value">{{ item.label }}</Option>
				</Select>
			</div>
			<div>
				<Button type="primary" @click="searchCk">搜索</Button>
			</div>
		</div>
		<!-- 添加按钮 -->
		<div class="user-add">
			<div>
				<Button type="primary" @click="doctorAdd">添加医生</Button>
			</div>
			<div>
				<Button type="primary" @click="returnUser">返回用户列表</Button>
			</div>
		</div>
		<!-- 表格 -->
		<div class="user-table">
			<Table :columns="columns" border :data="data" width="100rem">
			<!-- 	<template slot="action">
					<Button type="error" size="small">删除</Button>
				</template> -->
				<template slot-scope="{ row }" slot="doctor_type">
					<Button type="primary" size="small" style="margin-right: 5px"
						v-if="row.doctor_type == 1">全科</Button>
					<Button type="error" size="small" style="margin-right: 5px" v-if="row.doctor_type == 2">专家</Button>
				</template>
			</Table>
		</div>
		<!-- 分页 -->
		<div class="user-page">
			<Page :total="total" show-elevator :show-total="true" :current="page" @on-change="onChange"
				:page-size="pagesize" />
		</div>
		<!-- 弹出层 -->
		<Modal v-model="doctorPop" width="888px">
			<p slot="header" style="color:#2D8CF0;text-align:center">
				<Icon type="ios-information-circle"></Icon>
				<span>添加签约医生</span>
			</p>
			<div class="pop">
				<Form :label-width="100">
					<FormItem label="根据机构筛选">
						<Select placeholder="请选择机构..." clearable :filterable="true" @on-change="onChageSe">
							<Option v-for="item in equipmentList" :value="item.id" :key="item.id">{{ item.hos_name }}
							</Option>
						</Select>
					</FormItem>
					<FormItem label="是否根据科室划分">
						<RadioGroup @on-change="ckUserCy" v-model="userCk">
							<Radio :label="1">否</Radio>
							<Radio :label="2">是</Radio>
						</RadioGroup>
					</FormItem>
					<FormItem label="根据科类区分" v-show="userCk == 2">
						<Select placeholder="请选择科室..." clearable :filterable="true" @on-change="onChageDe">
							<Option v-for="item in departmentList" :value="item.id" :key="item.id">{{ item.name }}
							</Option>
						</Select>
					</FormItem>
					<FormItem label="选择医生">
						<Select placeholder="请选择医生..." clearable v-model="formDoctor.doctor_ids" :multiple="true">
							<Option v-for="item in doctorList" :value="item.id" :key="item.id">{{ item.realname }}
							</Option>
						</Select>
					</FormItem>
					<!-- <FormItem label="已选择医生">
						<Input v-model="textarea" type="textarea" placeholder="Enter something..."></Input>
					</FormItem> -->
				</Form>
			</div>
			<div slot="footer">
				<Button type="error" size="small" @click="doctorPop = false">取消</Button>
				<Button type="primary" size="small" @click="addDoctor">确认</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	export default {
		created() {
			this.id = this.$route.query.id;
			this.name = this.$route.query.name;
			this.userDoctorList();
		},
		data() {
			return {
				userCk: 1,
				textarea: "陈慧敏,张三丰",
				hle_id: "",
				// 职位称号
				equipmentList: [],
				departmentList: [],
				doctorList: [],
				formDoctor: {
					id: "",
					doctor_ids: []
				}, //添加医生
				type: [{
						value: 1,
						label: "全科医生"
					},
					{
						value: 2,
						label: "专家医生"
					},
				],
				id: "",
				name: "",
				total: 0,
				page: 1, //当前码
				pagesize: 7, //每页显示多少条
				doctorPop: false,
				searchData: {
					realname: "",
					doctor_type: ""
				},
				columns: [{
						title: '编号',
						key: 'id',
					},
					{
						title: '医生名称',
						key: 'realname',
					},
					{
						title: '所属机构',
						key: 'hos_name',
					},
					{
						title: '职位',
						key: 'title',
					},
					{
						title: '科室',
						key: 'name',
					},
					{
						title: '手机号',
						key: 'mobile',
					},
					{
						title: '类型',
						key: 'doctor_type',
						slot: 'doctor_type',
					},
					// {
					// 	title: '操作',
					// 	slot: 'action',
					// 	width: 100,
					// 	align: 'center',
					// }
				],
				data: []
			}
		},
		methods: {
			//添加医生
			doctorAdd() {
				this.doctorPop = true;
				let data = {};
				let url = this.httpsUrl + 'UserController/conditionShow';
				this.$http.post(url, data, true).then(result => {
					console.log(result.data)
					this.equipmentList = result.data
				});
			},
			//获取科室
			onChageSe(e) {
				this.hle_id = e;
				let data = {
					id: e,
					is_department: this.userCk
				};
				let url = this.httpsUrl + 'UserController/conditionDepartment';
				this.$http.post(url, data, true).then(result => {
					console.log(result);
					if (result.data.code == 1) {
						this.departmentList = [];
						this.doctorList = result.data.data
						return;
					}
					if (result.data.code == 2) {
						this.doctorList = [];
						this.departmentList = result.data.data
						return;
					}
				});
			},
			// 获取医生
			onChageDe(e) {
				let data = {
					id: e
				};
				let url = this.httpsUrl + 'UserController/conditionDoctor';
				this.$http.post(url, data, true).then(result => {
					console.log(result.data)
					this.doctorList = result.data
				});
			},
			returnUser() {
				this.$router.go(-1)
			},
			//医生收索
			searchCk() {
				this.page = 1;
				this.userDoctorList();
			},
			// 获取签约医生列表
			userDoctorList() {
				let data = {
					page: this.page,
					pagesize: this.pagesize,
					realname: this.searchData.realname,
					doctor_type: this.searchData.doctor_type,
					id: this.id
				};
				let url = this.httpsUrl + 'UserController/doctorsList';
				this.$http.post(url, data, true).then(result => {
					console.log(result.data.count);
					this.data = result.data.data
					this.total = result.data.count
				});
			},
			// 添加签约医生
			addDoctor() {
				if (this.formDoctor.doctor_ids == '') {
					this.$Message.info('至少选择一个医生');
					return;
				}
				let data = this.formDoctor;
				data.id = this.id
				let url = this.httpsUrl + 'UserController/addDoctor';
				this.$http.post(url, data, true).then(result => {
					if (result.data.code == 200) {
						this.$Message.info(result.data.msg);
						this.doctorPop = false;
						this.userDoctorList();
						return;
					}
					this.$Message.info(result.data.msg);
				});
			},
			// 分页页码获取
			onChange: function(currentPage) {
				this.page = currentPage;
				this.userDoctorList();
			},
			//是否根据科室划分
			ckUserCy(e) {
				this.userCk = e;
				if (this.userCk == 1) {
					this.onChageSe(this.hle_id);
				} else {
					this.onChageSe(this.hle_id);
				}
				return;
			}
		}
	}
</script>

<style>
	.user-mian {
		display: flex;
		flex-flow: column nowrap;
		background: #fff;
		padding: 20px 10px;
		border-radius: 10px;
	}

	.user-search {
		display: flex;
		flex-flow: row;
	}

	.user-search>div:last-of-type {
		margin-left: 10px;
	}

	.user-add {
		display: flex;
		flex-flow: row;
		height: 50px;
		align-items: flex-end;
		justify-content: space-between;
	}

	.user-table {
		margin-top: 10px;
	}

	.user-page {
		margin-top: 20px;
	}
</style>
