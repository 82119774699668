<template>
	<div class="user-mian">
		<!-- 用户设备详情 -->
		<div class="user-h2-text">
			<div class="user-h2-texts">
				<div>户主</div>
				<div style="color: orangered;margin: 0px 5px;">{{name}}</div>
				<div>的家属列表</div>
			</div>
		</div>
		<!-- 收索 -->
		<div class="user-search">
			<div>
				<Input v-model="searchData.user_name" clearable placeholder="请输入用户名称..." style="width: 300px" />
			</div>
			<div>
				<Button type="primary" @click="searchCk">搜索</Button>
			</div>
		</div>
		<!-- 添加按钮 -->
		<div class="user-add">
			<div>
				<Button type="primary" @click="adduser">添加就诊人</Button>
			</div>
			<div>
				<Button type="primary" @click="returnUser">返回用户列表</Button>
			</div>
		</div>
		<!-- 表格 -->
		<div class="user-table">
			<Table :columns="columns" border :data="data">
				<template slot-scope="{ row }" slot="action">
					<Button type="primary" size="small" style="margin-right: 5px"
						@click="EidtUserck(row.id)">编辑</Button>
					<Button type="error" size="small" style="margin-right: 5px" @click="delShow(row.id)">删除</Button>
				</template>
				<template slot-scope="{ row }" slot="gender">
					<Button type="primary" size="small" style="margin-right: 5px" v-if="row.gender == 1">男</Button>
					<Button type="error" size="small" style="margin-right: 5px" v-if="row.gender == 2">女</Button>
				</template>
			</Table>
		</div>
		<!-- 分页 -->
		<div class="user-page">
			<Page :total="total" show-elevator :show-total="true" :current="page" @on-change="onChange"
				:page-size="pagesize" />
		</div>
		<!-- 弹出层 -->
		<Modal v-model="userPop" width="888px">
			<p slot="header" style="color:#2D8CF0;text-align:center">
				<Icon type="ios-information-circle"></Icon>
				<span>添加家属</span>
			</p>
			<div class="pop">
				<Form :label-width="100">
					<FormItem label="手机号" v-show="userCk != 2">
						<Input placeholder="请输入手机号" v-model="formData.mobile" />
					</FormItem>
					<FormItem label="手机号" v-show="userCk == 2">
						<Input disabled v-model="formData.mobile" />
					</FormItem>
					<FormItem label="采用户主手机号">
						<RadioGroup @on-change="ckUserCy" v-model="userCk">
							<Radio :label="1">否</Radio>
							<Radio :label="2">是</Radio>
						</RadioGroup>
					</FormItem>
					<FormItem label="名称">
						<Input placeholder="请输入用户名称" v-model="formData.realname" />
					</FormItem>
					<FormItem label="关系">
						<Input placeholder="请输入与户主的关系" v-model="formData.relation" />
					</FormItem>
					<FormItem label="性别">
						<RadioGroup v-model="formData.gender">
							<Radio label="1">男</Radio>
							<Radio label="2">女</Radio>
						</RadioGroup>
					</FormItem>
				</Form>
			</div>
			<div slot="footer">
				<Button type="error" size="small" @click="userPop = false">取消</Button>
				<Button type="primary" size="small" @click="addSand()">确认</Button>
			</div>
		</Modal>
		<!-- 弹出层 -->
		<Modal v-model="EiatPop" width="888px">
			<p slot="header" style="color:#ED4014;text-align:center">
				<Icon type="ios-information-circle"></Icon>
				<span>编辑家属</span>
			</p>
			<div class="pop">
				<Form :label-width="100">
					<FormItem label="手机号">
						<Input placeholder="请输入手机号" v-model="formEidtData.mobile" />
					</FormItem>
					<FormItem label="名称">
						<Input placeholder="请输入用户名称" v-model="formEidtData.realname" />
					</FormItem>
					<FormItem label="关系">
						<Input placeholder="请输入与户主的关系" v-model="formEidtData.relation" />
					</FormItem>
					<FormItem label="性别">
						<RadioGroup v-model="formEidtData.gender">
							<Radio :label="1">男</Radio>
							<Radio :label="2">女</Radio>
						</RadioGroup>
					</FormItem>
				</Form>
			</div>
			<div slot="footer">
				<Button type="error" size="small" @click="EiatPop = false">取消</Button>
				<Button type="primary" size="small" @click="eidtSand()">确认</Button>
			</div>
		</Modal>
		<!-- 弹出层 -->
		<Modal v-model="delPop" width="444px">
			<p slot="header" style="color:#ED4014;text-align:center">
				<Icon type="ios-information-circle"></Icon>
				<span>删除用户</span>
			</p>
			<div class="pop">
				<div class="del">确定要删除此家属吗?</div>
			</div>
			<div slot="footer">
				<Button type="error" size="small" @click="delPop = false">取消</Button>
				<Button type="primary" size="small" @click="delSave()">确认</Button>
			</div>
		</Modal>
	</div>
</template>
<script>
	export default {
		created() {
			this.id = this.$route.query.id;
			this.name = this.$route.query.name;
			this.mobile = this.$route.query.mobile;
			this.userList();
		},
		data() {
			return {
				total: 0,
				userPop: false,
				EiatPop: false,
				delPop:false,
				userid:"",
				page: 1, //当前码
				id: "", //户主id
				name: "", //户主名称
				mobile: "", //户主电话号
				userCk: 1,
				pagesize: 8, //每页显示多少条
				searchData: { //搜索
					user_name: ""
				},
				formData: {
					relation_id: "",
					realname: "",
					gender: "",
					mobile: "",
					relation: ""
				},
				formEidtData: {
					realname: "",
					gender: "",
					mobile: "",
					id: "",
					relation: "",
					relation_id: "", //户主id
				},
				columns: [{
						title: '用户编号',
						key: 'id'
					},
					{
						title: '用户名称',
						key: 'realname'
					},
					{
						title: '户主关系',
						key: 'relation'
					},
					{
						title: '用户性别',
						key: 'gender',
						slot: 'gender',
					},
					{
						title: '手机号',
						key: 'mobile'
					},
					{
						title: '操作',
						slot: 'action',
						align: 'center'
					}
				],
				data: []
			}
		},
		methods: {
			//获取户主列表
			userList() {
				let data = {
					page: this.page,
					pagesize: this.pagesize,
					realname: this.searchData.user_name,
					id: this.id
				};
				let url = this.httpsUrl + 'UserController/patientsData';
				this.$http.post(url, data, true).then(result => {
					console.log(result.data.data);
					this.data = result.data.data
					this.total = result.data.count
				});
			},
			// 分页页码获取
			onChange: function(currentPage) {
				this.page = currentPage;

				this.userList();
			},
			//搜索接口
			searchCk() {
				this.page = 1;
				this.userList();
			},
			//添加用户
			adduser() {
				this.userPop = true;
			},
			// 添加用户
			addSand() {
				if (this.formData.mobile.length != 11) {
					this.$Message.info('手机号不能小于11位');
					return;
				}
				if (this.formData.realname == '') {
					this.$Message.info('用户名不能为空');
					return;
				}
				if (this.formData.gender == '') {
					this.$Message.info('请选择性别');
					return;
				}
				let data = this.formData;
				data.relation_id = this.id
				let url = this.httpsUrl + 'UserController/userFamily';
				this.$http.post(url, data, true).then(result => {
					if (result.data.code == 200) {
						this.$Message.info(result.data.msg);
						this.userPop = false;
						this.userList();
						return;
					}
					this.$Message.info(result.data.msg);
				});
			},
			//编辑用户
			EidtUserck(id) {
				this.EiatPop = true;
				let data = {
					id: id
				}
				console.log(data);
				let url = this.httpsUrl + 'UserController/userFamilyFind';
				this.$http.post(url, data, true).then(result => {
					console.log(result.data.data);
					this.formEidtData = result.data.data
				});
			},
			//发送修改数据
			eidtSand() {
				let data = this.formEidtData;
				data.relation_id = this.id;
				let url = this.httpsUrl + 'UserController/userEidtFIndSave';
				this.$http.post(url, data, true).then(result => {
					if (result.data.code == 200) {
						this.$Message.info(result.data.msg);
						this.EiatPop = false;
						this.userList();
						return;
					}
					this.$Message.info(result.data.msg);
				});
			},
			// 返回用户列表
			returnUser() {
			   
				this.$router.go(-1);
			},
			//是否采用户主手机号
			ckUserCy(e) {
				this.userCk = e;
				if (this.userCk == 2) {
					this.formData.mobile = this.mobile
				} else {
					this.formData.mobile = ""
				}
			},
			// 删除用户
			delShow(id){
				this.delPop = true;
				this.userid  = id;
			},
			delSave(){
				let data = {
					id:this.userid
				}
				let url = this.httpsUrl + 'UserController/delSavemember';
				this.$http.post(url, data, true).then(result => {
					if(result.data.code == 200){
						this.$Message.info(result.data.msg);
						this.delPop = false;
						this.userList();
						return;
					}
					this.$Message.info(result.data.msg);
				});
			}
		}
	}
</script>

<style>
	.user-mian {
		display: flex;
		flex-flow: column nowrap;
		background: #fff;
		padding: 20px 10px;
		border-radius: 10px;
	}

	.user-search {
		display: flex;
		flex-flow: row;
	}

	.user-search>div:last-of-type {
		margin-left: 10px;
	}

	.user-add {
		display: flex;
		flex-flow: row;
		height: 50px;
		align-items: flex-end;
		justify-content: space-between;
	}

	.user-table {
		margin-top: 10px;
	}

	.user-page {
		margin-top: 20px;
	}

	.user-h2-text {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		height: 50px;
		align-items: center;
	}

	.user-h2-texts {
		display: flex;
		flex-flow: row;
		font-size: 20px;
		margin-bottom: 10px;
	}
</style>
