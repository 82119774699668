<template>
	<div class="user-mian">
		<!-- 用户设备详情 -->
		<div class="user-h2-text">
			<div class="user-h2-texts">
				<div>医生</div>
				<div style="color: orangered;margin: 0px 5px;">{{name}}</div>
				<div>签约用户</div>
			</div>
			<div>
				<Button type="primary" @click="returnUser">返回医生列表</Button>
			</div>
		</div>
		<!-- 收索 -->
		<div class="user-search">
			<div>
				<Input v-model="searchData.user_name" clearable placeholder="请输入用户名称..." style="width: 300px" />
			</div>
			<div>
				<Button type="primary" @click="searchCk">搜索</Button>
			</div>
		</div>
		<!-- 返回按钮 -->
		<div class="user-table">
			<Table :columns="columns" border :data="data">
				<template slot="action">
					<Button type="error" size="small">删除</Button>
				</template>
				<template slot-scope="{ row }" slot="gender">
					<Button type="primary" size="small" style="margin-right: 5px" v-if="row.gender == 1">男</Button>
					<Button type="error" size="small" style="margin-right: 5px" v-if="row.gender == 2">女</Button>
					<Button type="primary" size="small" style="margin-right: 5px" v-if="row.gender == 0">未知</Button>
				</template>
			</Table>
		</div>
		<!-- 分页 -->
		<div class="user-page">
			<Page :total="total" show-elevator :show-total="true" :current="page" @on-change="onChange" :page-size="pagesize"/>
		</div>
	</div>
</template>

<script>
	export default {
		created() {
			this.id = this.$route.query.id;
			this.name = this.$route.query.name;
			this.doctorUserList();
		},
		data() {
			return {
				id:"",
				name:"",
				total:0,
				page:1,//当前码
				pagesize:10,//每页显示多少条
				searchData: {
					user_name: ""
				},
				columns: [{
						title: '用户编号',
						key: 'id'
					},
					{
						title: '用户名称',
						key: 'realname'
					},
					{
						title: '用户性别',
						key: 'gender',
						slot: 'gender',
					},
					{
						title: '手机号',
						key: 'mobile'
					},
					{
						title: '操作',
						slot: 'action',
						width: 400,
						align: 'center'
					}
				],
				data: []
			}
		},
		methods: {
			//搜索接口
			searchCk(){
				this.page = 1;
				this.doctorUserList();
			},
			// 分页页码获取
			onChange: function(currentPage) {
				this.page = currentPage;
				this.doctorUserList();
			},
			// 医生签约列表
			doctorUserList(){
				let data = {
					page: this.page,
					pagesize: this.pagesize,
					realname:this.searchData.user_name,
					id:this.id
				};
				let url = this.httpsUrl + 'doctorController/doctorUserList';
				this.$http.post(url, data, true).then(result => {
					console.log(result.data.data)
					this.data = result.data.data
					this.total = result.data.count
				});
			},
			returnUser(){
				this.$router.go(-1)
			}
		}
	}
</script>

<style>
	.user-mian {
		display: flex;
		flex-flow: column nowrap;
		background: #fff;
		padding: 20px 10px;
		border-radius: 10px;
	}

	.user-search {
		display: flex;
		flex-flow: row;
	}

	.user-search>div:last-of-type {
		margin-left: 10px;
	}

	.user-add {
		display: flex;
		flex-flow: row;
		height: 50px;
		align-items: flex-end;
	}

	.user-table {
		margin-top: 10px;
	}
	.user-page {
		margin-top: 20px;
	}
	.user-h2-text {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		height: 50px;
		align-items: center;
	}
	.user-h2-texts {
		display: flex;
		flex-flow: row;
		font-size: 20px;
		margin-bottom: 10px;
	}
</style>
