<template>
	<!-- 主体 -->
	<div class="mian">
		<div class="mian-haed">
			<h2>用户注册后台管理系统</h2>
			<!-- 头部信息和退出按钮 -->
			<div class="mian-haed-info">
				<!-- 头部信息 -->
				<div>
					<img src="../../assets/login.png" alt="" />
					<p>
						<Dropdown>
							<a href="javascript:void(0)" style="color: #FFFFFF;">
								admin
								<Icon type="ios-arrow-down"></Icon>
							</a>
							<DropdownMenu slot="list">
								<DropdownItem @click.native="userInfoCK">修改个人信息</DropdownItem>
								<DropdownItem @click.native="loginOut">退出登陆</DropdownItem>
							</DropdownMenu>
						</Dropdown>
					</p>
				</div>
			</div>
			<Modal v-model="modelPop" width="360">
				<p slot="header" style="color:#f60;text-align:center">
					<Icon type="ios-information-circle"></Icon>
					<span>确认要退出吗?</span>
				</p>
				<div style="text-align:center">
					<p>退出按下退出件即可,取消点击右上角X</p>
					<p>退出后将跳转至登陆页面...</p>
				</div>
				<div slot="footer"><Button type="error" size="large" long :loading="modal_loading"
						@click="del">退出</Button></div>
			</Modal>
		</div>
		<div class="mian-content">
			<div class="mian-content-left">
				<Menu theme="light" width="200px" @on-select="menuSele" :open-names="[open_names]"
					:active-name="active_name">
					<Submenu name="1">
						<template slot="title">
							<Icon type="ios-people" size="20" />
							用户管理
						</template>
						<MenuItem name="1-2" to="/user_list">用户列表</MenuItem>
					</Submenu>
					<Submenu name="2">
						<template slot="title">
							<Icon type="ios-person-add" size="20" />
							医生管理
						</template>
						<MenuItem name="2-1" to="/doctors_list">医生列表</MenuItem>
					</Submenu>
					<Submenu name="3">
						<template slot="title">
							<Icon type="ios-phone-landscape" size="20" />
							设备管理
						</template>
						<MenuItem name="3-1" to="/mechanism_list">设备列表</MenuItem>
					</Submenu>
					<Submenu name="4">
						<template slot="title">
							<Icon type="ios-school" size="20" />
							机构管理
						</template>
						<MenuItem name="4-1" to="/equipment_list">机构列表</MenuItem>
					</Submenu>
					<Submenu name="6">
						<template slot="title">
							<Icon type="ios-cog-outline" size="22" />
							排班管理
						</template>
						<MenuItem name="6-1" to="/doctor_schedule">医生排班列表</MenuItem>
						<MenuItem name="6-2" to="/mechanism_schedule">机构排班列表</MenuItem>
					</Submenu>
					<Submenu name="5">
						<template slot="title">
							<Icon type="logo-codepen" size="20" />
							小程序分发
						</template>
						<MenuItem name="5-1" to="/applets_user">小程序用户户主</MenuItem>
						<MenuItem name="5-2" to="/applets_doctro">小程序用户医生</MenuItem>
					</Submenu>
				</Menu>
			</div>
			<div class="mian-content-right">
				<!-- 面包屑 -->
				<div>
					<div v-for="(item, index) in routers" :key="index" :class="[item]">
						<p @click="toRouter(index)">{{ item.name }}</p>
						<Icon type="md-close" @click="dlRouter(index)" />
					</div>
				</div>
				<!-- 表格 -->
				<div class="index-table">
					<router-view v-slot="{ Component }">
						<keep-alive>
							<component :is="Component" :key="$route.fullPath" />
						</keep-alive>
					</router-view>
				</div>
			</div>
		</div>
		<div class="mian-footer" style="display: none;">底部</div>
		<Modal v-model="modal2" width="430px">
			<p slot="header" style="color:#f60;text-align:center">
				<Icon type="ios-information-circle"></Icon>
				<span>修改个人资料</span>
			</p>
			<div class="popSave">
				<div>
					<div>管理员昵称</div>
					<div>
						<Input placeholder="请输入要更改的管理员密码..." style="width: 300px" />
					</div>
				</div>
				<div>
					<div>输入新密码</div>
					<div>
						<Input placeholder="请输入原先密码..." style="width: 300px" />
					</div>
				</div>
				<div>
					<div>确认新密码</div>
					<div>
						<Input placeholder="确认新密码..." style="width: 300px" />
					</div>
				</div>
			</div>
			<div slot="footer">
				<Button type="error" size="small">取消</Button>
				<Button size="small">确认</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				routers: [{
					name: '主页',
					link: '/'
				}],
				crumbData: '',
				index: null, //规格默认下标
				isAdd: true,
				modelPop: false,
				modal_loading: false,
				modal2: false,
				active_name: "",
				open_names: "",
			};
		},
		methods: {
			toRouter: function(index) {
				let path = this.routers[index].link;
				let name = this.routers[index].name;
				// 判断是否路由重复,重复则不跳转
				if (name != this.$route.matched[1].name) {
					this.$router.push(path);
				}
			},
			// dlRouter: function(index) {
			// 	if (index != 0) {
			// 		this.routers.splice(index, 1);
			// 		let data = this.$router;
			// 		// this.$router.push(path);
			// 	}
			// 	return;
			// },
			dlRouter: function(index) {
				if (index != 0) {
					this.routers.splice(index, 1);
					let path = this.routers[this.routers.length - 1];
					this.$router.push(path.link);
				}
				return;
			},
			// 退出登陆
			loginOut: function() {
				this.modelPop = true;
			},
			del: function() {
				this.modal_loading = true;
				setTimeout(() => {
					// 清楚token;
					sessionStorage.setItem('token', '');
					this.modal_loading = false;
					this.modelPop = false;
					this.$Message.success('退出成功');
					window.top.location.href = "http://localhost:8080/login";
				}, 1000);
			},
			userInfoCK() {
				this.modal2 = true;
			},
			menuSele(e) {
				sessionStorage.setItem('active-name', e);
				let names = sessionStorage.getItem('active-name').substring(0, 1);
				sessionStorage.setItem('open-names', names);
			}
		},
		watch: {
			$route() {
				// [{name: 'home'}, {name:'list'}] 这个是路由里面一个对象配置
				this.isAdd = true;
				// crumbData可以生成超链接模块，拼接成路由连接和名称，
				let crumbData = this.$route.matched;
				this.routers.map(val => {
					if (val.name == this.$route.matched[1].name) {
						this.isAdd = false;
					}
				});
				if (this.isAdd != false) {
					this.routers.push({
						name: crumbData[1].name,
						link: crumbData[1].path
					});
				}
			}
		},
		// computed: {
		// 	routerList() {
		// 		return this.$route.matched;
		// 	}
		// },
		created() {
			let matcheds = this.$route.matched;
			this.routers.push({
				name: matcheds[1].name,
				link: matcheds[1].path
			});
			this.active_name = sessionStorage.getItem('active-name');
			this.open_names = sessionStorage.getItem('open-names');
		}
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	/* 主体 */
	.mian {
		display: flex;
		flex-flow: column;
		height: 100vh;
		background-color: #f5f7f9;
	}

	/* 头部 */
	.mian-haed {
		display: flex;
		flex-flow: row;
		height: 70px;
		justify-content: space-between;
		align-items: center;
		background-color: #515a6e;
		color: #fff;
		position: fixed;
		top: 0px;
		width: 100%;
	}

	.mian-haed>h2 {
		display: flex;
		flex-flow: row;
		flex: 8;
		justify-content: flex-start;
		align-items: center;
		margin-left: 20px;
	}

	/* 左部信息 */
	.mian-haed-info {
		display: flex;
		flex-flow: row;
		flex: 2;
		justify-content: space-evenly;
	}

	.mian-haed-info>div:first-of-type {
		display: flex;
		flex-flow: row;
		align-items: center;
	}

	.mian-haed-info>div:first-of-type>img {
		width: 45px;
		height: 45px;
		border-radius: 50%;
		background: #fff;
	}

	.mian-haed-info>div:first-of-type>p {
		margin-left: 20px;
	}

	.mian-haed-info>div:last-of-type {
		display: flex;
		flex-flow: row;
		align-items: center;
	}

	/* 内容 */
	.mian-content {
		display: flex;
		flex-flow: row;
		flex: 1;
	}

	.mian-content-left {
		display: flex;
		flex-flow: row;
		background: #42b983;
		position: fixed;
		top: 70px;
		z-index: 10;
		height: 100%;
	}

	/* 内容右部 */
	.mian-content-right {
		margin-top: 70px;
		display: flex;
		flex-flow: column;
		flex: 1;
		margin-left: 210px;
		margin-right: 10px;

	}

	/* 头部面包屑 */
	.mian-content-right>div:first-of-type {
		display: flex;
		flex-flow: row;
		margin: 10px 0px;
	}

	.mian-content-right>div:first-of-type>div {
		display: flex;
		flex-flow: row;
		align-items: center;
		justify-content: center;
		padding: 5px 10px;
		background-color: #ffffff;
		margin-right: 10px;
		color: #808695;
	}

	.mian-content-right>div:first-of-type>div>p {
		margin-right: 5px;
	}

	/* 底部 */
	.mian-footer {
		display: flex;
		flex-flow: row;
		height: 50px;
		background-color: #515a6e;
	}

	.popSave {
		display: flex;
		flex-flow: column;
	}

	.popSave>div {
		display: flex;
		flex-flow: row;
		height: 60px;
		width: 100%;
		align-items: center;
	}

	.popSave>div>div:first-of-type {
		margin-right: 20px;
	}

	.index-table {
		display: flex;
		flex-flow: column;
		flex: 1;
	}
	.chek {
		color: red;
	}
</style>
