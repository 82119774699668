<template>
	<div class="user-mian">
		<!-- 收索 -->
		<div class="user-search">
			<div>
				<Input v-model="searchData.mobile" clearable placeholder="请输入手机号..." style="width: 300px" />
			</div>
			<div>
				<Button type="primary" @click="searchCk">搜索</Button>
			</div>
		</div>
		<div class="user-table">
			<Table :columns="columns" border :data="data">
				<template slot="action" slot-scope="{ row }">
					<Button type="error" size="small" @click="sandUser(row)">发送信息给医生</Button>
				</template>
				<template slot-scope="{ row }" slot="is_sand">
					<Button size="small" v-if="row.is_sand == 1">未发送</Button>
					<Button type="primary" size="small" v-if="row.is_sand == 2">已发送</Button>
				</template>
			</Table>
		</div>
		<!-- 分页 -->
		<div class="user-page">
			<Page :total="total" show-elevator :show-total="true" :current="page" @on-change="onChange" :page-size="pagesize"/>
		</div>
		<!-- 弹出层 -->
		<Modal v-model="doctorPop" width="666px">
			<p slot="header" style="color:#2D8CF0;text-align:center">
				<Icon type="ios-information-circle"></Icon>
				<span>确认要通知用户吗</span>
			</p>
			<div class="pop">
				<div style="display: flex;flex-flow: row;align-items: center;justify-content: center;font-size: 18px;">
					发送成功医生即可通过移动发送的链接跳转到h5
				</div>
			</div>
			<div slot="footer">
				<Button type="error" size="small" @click="doctorPop = false">取消</Button>
				<Button type="primary" size="small" @click="doctorSandSMS()">确认</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	export default {
		created() {
			this.userList();
		},
		data() {
			return {
				id:"",
				name:"",
				doctorPop: false,
				total: 0,
				page: 1, //当前码
				pagesize: 8, //每页显示多少条
				searchData: {
					mobile: ""
				},
				columns: [{
						title: '编号',
						key: 'id'
					},
					{
						title: '用户昵称',
						key: 'realname'
					},
					{
						title: '手机号',
						key: 'mobile'
					},
					{
						title: '状态',
						key: 'is_sand',
						slot: 'is_sand',
					},
					{
						title: '操作',
						slot: 'action',
						width: 300,
						align: 'center'
					}
				],
				data: []
			}
		},
		methods: {
			//通知用户
			sandUser(row) {
				this.id = row.id;
				this.name = row.realname;
				this.doctorPop = true;
			},
			// 分页页码获取
			onChange: function(currentPage) {
				this.page = currentPage;
				this.userList();
			},
			//搜索接口
			searchCk(){
				this.page = 1;
				this.userList();
			},
			userList() {
				let data = {
					page: this.page,
					pagesize: this.pagesize,
					mobile:this.searchData.mobile
				};
				let url = this.httpsUrl + 'appletsController/doctorLists';
				this.$http.post(url, data, true).then(result => {
					console.log(result.data.data)
					this.data = result.data.data
					this.total = result.data.count
				});
			},
			//发送短信提醒
			doctorSandSMS() {
				let data = {
					id:this.id
				};
				let url = this.httpsUrl + 'appletsController/doctorSandSMS';
				this.$http.post(url, data, true).then(result => {
					if(result.data.code == 200){
						this.$Message.info(result.data.msg);
						this.doctorPop = false;
						this.userList();
						return;
					}
					this.$Message.info(result.data.msg);
				});
			}
		}
	}
</script>

<style>
	.user-mian {
		display: flex;
		flex-flow: column nowrap;
		background: #fff;
		padding: 20px 10px;
		border-radius: 10px;
	}

	.user-search {
		display: flex;
		flex-flow: row;
	}

	.user-search>div:last-of-type {
		margin-left: 10px;
	}

	.user-add {
		display: flex;
		flex-flow: row;
		height: 50px;
		align-items: flex-end;
	}

	.user-table {
		margin-top: 10px;
	}

	.user-page {
		margin-top: 20px;
	}
</style>
