<template>
	<div class="user-mian">
		<!-- 用户设备详情 -->
		<div class="user-h2-text">
			<div class="user-h2-texts">
				<div>机构</div>
				<div style="color: orangered;margin: 0px 5px;">{{hos_name}}</div>
				<div>的设备列表</div>
			</div>
			<div>
				<Button type="primary" @click="returnUser">返回机构列表</Button>
			</div>
		</div>
		<!-- 收索 -->
		<div class="user-search">
			<div>
				<Input v-model="searchData.box_name" placeholder="请输入设备名称..." style="width: 300px" />
			</div>
			<div>
				<Button type="primary" @click="searchCk">搜索</Button>
			</div>
		</div>
		<!-- 添加按钮 -->
		<div class="user-add">
			<div>
				<Button type="primary" @click="doctorAdd">添加设备</Button>
			</div>
		</div>
		<!-- 表格 -->
		<div class="user-table">
			<Table :columns="columns" border :data="data" width="100rem">
				<template slot="action">
					<Button type="error" size="small">删除</Button>
				</template>
				<template slot-scope="{ row }" slot="equipment_type">
					<Button type="primary" size="small" style="margin-right: 5px" v-if="row.equipment_type == 3">机构</Button>
				</template>
			</Table>
		</div>
		<!-- 分页 -->
		<div class="user-page">
			<Page :total="total" show-elevator :show-total="true" :current="page" @on-change="onChange" :page-size="pagesize"/>
		</div>
		<!-- 弹出层 -->
		<Modal v-model="doctorPop" width="888px">
			<p slot="header" style="color:#2D8CF0;text-align:center">
				<Icon type="ios-information-circle"></Icon>
				<span>添加设备</span>
			</p>
			<div class="pop">
				<Form :label-width="100">
					<FormItem label="设备类型">
						<Select placeholder="根据设备类型筛选..." :filterable="true" @on-change = "onChageSe" >
							<Option v-for="item in equipmentType" :value="item.value" :key="item.value">{{ item.label }}
							</Option>
						</Select>
					</FormItem>
					<FormItem label="选择设备">
						<Select placeholder="请选择设备..." v-model="formData.equipment_id">
							<Option v-for="item in equipmentList" :value="item.id" :key="item.id">{{ item.box_name }}
							</Option>
						</Select>
					</FormItem>
					<FormItem label="MAC地址">
						<Input placeholder="请输入MAC地址" v-model="formData.mac" />
					</FormItem>
				</Form>
			</div>
			<div slot="footer">
				<Button type="error" size="small" @click="doctorPop = false">取消</Button>
				<Button type="primary" size="small" @click="addmechanism">确认</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	export default {
		created() {
			this.id = this.$route.query.id;
			this.hos_name = this.$route.query.name;
			this.mechanismList();
		},
		data() {
			return {
				id:"",
				hos_name:"",
				equipmentType:[
					{
						value:"C3",
						label: "C3"
					},
					{
						value:"C5",
						label: "C5"
					},
					{
						value:"8寸音箱",
						label: "8寸音箱"
					},
					{
						value:"10寸音箱",
						label: "10寸音箱"
					},
					{
						value:"机顶盒",
						label: "机顶盒"
					},
				],
				equipmentList:[],
				formData:{
					equipment_id:"",
					mac:"",
					user_id:"",
				},
				total: 0,
				page: 1, //当前码
				pagesize: 7, //每页显示多少条
				doctorPop: false,
				searchData: {
					box_name: ""
				},
				columns: [{
						title: '编号',
						key: 'box_id',
					},
					{
						title: '设备名称',
						key: 'box_name',
					},
					{
						title: 'MAC地址',
						key: 'mac',
					},
					{
						title: '设备类型',
						key: 'box_type',
					},
					{
						title: '所属类型',
						key: 'equipment_type',
						slot: 'equipment_type',
					},
					{
						title: '操作',
						slot: 'action',
						width: 300,
						align: 'center',
					}
				],
				data: []
			}
		},
		methods: {
			//添加设备
			doctorAdd() {
				this.doctorPop = true;
			},
			returnUser(){
				this.$router.go(-1)
			},
			//搜索接口
			searchCk(){
				this.page = 1;
				this.mechanismList();
			},
			// 分页页码获取
			onChange: function(currentPage) {
				this.page = currentPage;
				this.mechanismList();
			},
			//获取设备列表
			mechanismList() {
				let data = {
					page: this.page,
					pagesize: this.pagesize,
					box_name:this.searchData.box_name,
					id:this.id
				};
				let url = this.httpsUrl + 'equipmentController/equipmentListShow';
				this.$http.post(url, data, true).then(result => {
					console.log(result.data.data);
					this.data = result.data.data
					this.total = result.data.count
				});
			},
			//获取设备
			onChageSe(e){
				let data = {
					box_type:e
				};
				let url = this.httpsUrl + 'UserController/filterEquipment';
				this.$http.post(url, data, true).then(result => {
					console.log(result.data)
					this.equipmentList = result.data.data
				});
			},
			//添加设备
			addmechanism(){
				if(this.formData.equipment_id == ''){
					this.$Message.info('请选择设备');
					return;
				}
				if(this.formData.mac == ''){
					this.$Message.info('mac地址必填');
				}
				this.formData.user_id = this.id;
				let data = this.formData;
				let url = this.httpsUrl + 'equipmentController/addEquipmentMeSand';
				this.$http.post(url, data, true).then(result => {
					if(result.data.code == 200){
						this.$Message.info(result.data.msg);
						this.doctorPop = false;
						this.mechanismList();
						return;
					}
					this.$Message.info(result.data.msg);
				});
			}
		}
	}
</script>

<style>
	.user-mian {
		display: flex;
		flex-flow: column nowrap;
		background: #fff;
		padding: 20px 10px;
		border-radius: 10px;
	}

	.user-search {
		display: flex;
		flex-flow: row;
	}

	.user-search>div:last-of-type {
		margin-left: 10px;
	}

	.user-add {
		display: flex;
		flex-flow: row;
		height: 50px;
		align-items: flex-end;
	}

	.user-table {
		margin-top: 10px;
	}

	.user-page {
		margin-top: 20px;
	}
	.user-h2-text {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		height: 50px;
		align-items: center;
	}
	.user-h2-texts {
		display: flex;
		flex-flow: row;
		font-size: 20px;
		margin-bottom: 10px;
	}
</style>
