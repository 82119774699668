<template>
	<div class="user-mian">
		<!-- 收索 -->
		<div class="user-search">
			<div>
				<Input v-model="searchData.box_id" clearable placeholder="请输入设备唯一标识box_id..." style="width: 300px" />
			</div>
			<div style="margin-left: 10px;">
				<Select placeholder="请选择设备类型..." clearable style="width: 200px" v-model="searchData.box_type">
					<Option v-for="item in equipmentType" :value="item.value" :key="item.value">{{ item.label }}</Option>
				</Select>
			</div>
			<div style="margin-left: 10px;">
				<Select placeholder="根据条件筛选设备是否绑定..." clearable style="width: 200px" v-model="searchData.is_bind">
					<Option v-for="item in type" :value="item.value" :key="item.value">{{ item.label }}</Option>
				</Select>
			</div>
			<div>
				<Button type="primary" @click="searchCk">搜索</Button>
			</div>
		</div>
		<!-- 添加按钮 -->
		<div class="user-add">
			<div>
				<Button type="primary" @click="doctorAdd">添加设备</Button>
			</div>
		</div>
		<!-- 表格 -->
		<div class="user-table">
			<Table :columns="columns" border :data="data" width="100rem">
				<template slot="action" slot-scope="{ row }">
					<Button type="primary" size="small" style="margin-right: 5px" @click="eidtShowMechanism(row.id)">编辑</Button>
		<!-- 			<Button type="error" size="small">删除</Button> -->
				</template>
				<template slot-scope="{ row }" slot="mac">
					<Button size="small" style="margin-right: 5px" v-if="row.mac == null">暂无mac地址</Button>
					<div>{{row.mac}}</div>
				</template>
				<template slot-scope="{ row }" slot="equipment_type">
					<Button size="small" style="margin-right: 5px" v-if="row.equipment_type == null">暂无类型</Button>
					<Button type="primary" size="small" style="margin-right: 5px" v-if="row.equipment_type == 1">个人</Button>
					<Button type="error" size="small" style="margin-right: 5px" v-if="row.equipment_type == 3">机构</Button>
				</template>
				<template slot-scope="{ row }" slot="is_bind">
					
					<Button type="primary" size="small" style="margin-right: 5px" v-if="row.is_bind == 0">否</Button>
					<Button type="error" size="small" style="margin-right: 5px" v-if="row.is_bind == 1">是</Button>
				</template>
				<template slot-scope="{ row }" slot="role">
					<Button size="small" style="margin-right: 5px" v-if="row.role == null">暂无用户</Button>
					<Button type="primary" size="small" style="margin-right: 5px" v-if="row.role == 1">用户</Button>
					<Button type="error" size="small" style="margin-right: 5px" v-if="row.role == 2">医生</Button>
					<Button type="error" size="small" style="margin-right: 5px" v-if="row.role == 3">机构</Button>
				</template>
			</Table>
		</div>
		<!-- 分页 -->
		<div class="user-page">
			<Page :total="total" show-elevator :show-total="true" :current="page" @on-change="onChange" :page-size="pagesize"/>
		</div>
		<!-- 弹出层 -->
		<Modal v-model="doctorPop" width="888px">
			<p slot="header" style="color:#2D8CF0;text-align:center">
				<Icon type="ios-information-circle"></Icon>
				<span>添加设备</span>
			</p>
			<div class="pop">
				<Form :label-width="100">
					<FormItem label="设备box_id">
						<Input placeholder="请输入设备唯一标识"  v-model = "formData.box_id" />
					</FormItem>
					<FormItem label="设备名称">
						<Input placeholder="请输入设备名称" v-model = "formData.box_name"/>
					</FormItem>
					<FormItem label="设备类型" >
						<Select placeholder="请选择设备类型..." v-model="formData.box_type">
							<Option v-for="item in equipmentType" :value="item.value" :key="item.value">{{ item.label }}
							</Option>
						</Select>
					</FormItem>
				</Form>
			</div>
			<div slot="footer">
				<Button type="error" size="small" @click="doctorPop = false">取消</Button>
				<Button type="primary" size="small" @click="addMechanism">确认</Button>
			</div>
		</Modal>
		
		<!-- 编辑弹出层 -->
		<Modal v-model="eidtMechanism" width="888px">
			<p slot="header" style="color:#ED4014;text-align:center">
				<Icon type="ios-information-circle"></Icon>
				<span>编辑设备</span>
			</p>
			<div class="pop">
				<Form :label-width="100">
					<FormItem label="设备box_id">
						<Input placeholder="请输入设备唯一标识"  v-model = "formEidtData.box_id" />
					</FormItem>
					<FormItem label="设备名称">
						<Input placeholder="请输入设备名称" v-model = "formEidtData.box_name"/>
					</FormItem>
					<FormItem label="设备类型" >
						<Select placeholder="请选择设备类型..." v-model="formEidtData.box_type">
							<Option v-for="item in equipmentType" :value="item.value" :key="item.value">{{ item.label }}
							</Option>
						</Select>
					</FormItem>
				</Form>
			</div>
			<div slot="footer">
				<Button type="error" size="small" @click="eidtMechanism = false">取消</Button>
				<Button type="primary" size="small" @click="eidtSandMechanism">确认</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	export default {
		created() {
			this.mechanismList();
		},
		data() {
			return {
				equipmentType:[
					{
						value:"C3",
						label: "C3"
					},
					{
						value:"C5",
						label: "C5"
					},
					{
						value:"8寸音箱",
						label: "8寸音箱"
					},
					{
						value:"10寸音箱",
						label: "10寸音箱"
					},
					{
						value:"机顶盒",
						label: "机顶盒"
					},
				],
				type: [{
						value: 0,
						label: "未绑定"
					},
					{
						value: 1,
						label: "已绑定"
					},
				],
				gertype: [{
						value: 1,
						label: "个人"
					},
					{
						value: 2,
						label: "机构"
					},
				],
				total: 0,
				page: 1, //当前码
				pagesize: 8, //每页显示多少条
				doctorPop: false,
				eidtMechanism:false,
				searchData: {
					box_id: "",
					equipment_type:"",
					box_type:"",
					is_bind:"",
				},
				formData:{
					box_id:"",
					box_name:"",
					box_type:"",
				},
				formEidtData:{
					id:"",
					box_id:"",
					box_name:"",
					box_type:"",
				},
				columns: [{
						title: '编号',
						key: 'id',
					},
					{
						title: '设备唯一标识box_id',
						key: 'box_id',
					},
					{
						title: 'MAC地址',
						key: 'mac',
						slot: 'mac',
					},
					{
						title: '所属用户',
						key: 'role',
						slot: 'role',
						width: 100,
					},
					{
						title: '设备类型',
						key: 'box_type',
						width: 150,
					},
					{
						title: '所属类型',
						key: 'equipment_type',
						slot: 'equipment_type',
						width: 100,
					},
					{
						title: '是否绑定',
						key: 'is_bind',
						slot: 'is_bind',
						width: 100,
					},
					{
						title: '操作',
						slot: 'action',
						width: 150,
						align: 'center',
					}
				],
				data: [{
						id: 1,
						name: "测试设备111",
						equipment: '127.0.0.1',
						position: 'c3',
						kesh: '个人',
						create_time: "2021-03-16 12:01:01"
					},
					{
						id: 1,
						name: "测试设备222",
						equipment: '127.0.0.1',
						position: '8存音箱',
						kesh: '机构',
						create_time: "2021-03-16 12:01:01"
					},
					{
						id: 1,
						name: "测试设备333",
						equipment: '127.0.0.1',
						position: '10寸音响',
						kesh: '个人',
						create_time: "2021-03-16 12:01:01"
					},
				]
			}
		},
		methods: {
			//添加设备
			doctorAdd() {
				this.doctorPop = true;
			},
			//添加设备发送数据
			addMechanism() {
				if(this.formData.box_id == '') {
					this.$Message.info('设备唯一标识不能为空');
					return;
				}
				if(this.formData.box_name == '') {
					this.$Message.info('设备名称不能为空');
					return;
				}
				if(this.formData.box_type == '') {
					this.$Message.info('设备类型不能为空');
					return;
				}
				let data = this.formData;
				let url = this.httpsUrl + 'mechanismController/addMechanism';
				this.$http.post(url, data, true).then(result => {
					if(result.data.code == 200){
						this.$Message.info(result.data.msg);
						this.doctorPop = false;
						this.mechanismList();
						return;
					}
					this.$Message.info(result.data.msg);
				});
			},
			// 获取设备列表
			mechanismList(){
				let data = {
					page: this.page,
					pagesize: this.pagesize,
					box_id:this.searchData.box_id,
					equipment_type:this.searchData.equipment_type,
					box_type:this.searchData.box_type,
					is_bind:this.searchData.is_bind
				};
				let url = this.httpsUrl + 'mechanismController/mechanismList';
				this.$http.post(url, data, true).then(result => {
					console.log(result.data.data);
					this.data = result.data.data
					this.total = result.data.count
				});
			},
			// 分页页码获取
			onChange: function(currentPage) {
				this.page = currentPage;
				this.mechanismList();
			},
			//搜索接口
			searchCk(){
				this.page = 1;
				this.mechanismList();
			},
			// 获取设备基本信息
			eidtShowMechanism(e){
				this.eidtMechanism = true
				let data = {
					id:e
				}
				let url = this.httpsUrl + 'mechanismController/eidtShowMechanism';
				this.$http.post(url, data, true).then(result => {
					this.formEidtData = result.data.data
				});
			},
			//编辑设备Send
			eidtSandMechanism(){
				if(this.formEidtData.box_id == '') {
					this.$Message.info('设备唯一标识不能为空');
					return;
				}
				if(this.formEidtData.box_name == '') {
					this.$Message.info('设备名称不能为空');
					return;
				}
				if(this.formEidtData.box_type == '') {
					this.$Message.info('设备类型不能为空');
					return;
				}
				let data = this.formEidtData;
				let url = this.httpsUrl + 'mechanismController/eidtSandMechanism';
				this.$http.post(url, data, true).then(result => {
					if(result.data.code == 200){
						this.$Message.info(result.data.msg);
						this.eidtMechanism = false;
						this.mechanismList();
						return;
					}
					this.$Message.info(result.data.msg);
				});
			}
		}
	}
</script>

<style>
	.user-mian {
		display: flex;
		flex-flow: column nowrap;
		background: #fff;
		padding: 20px 10px;
		border-radius: 10px;
	}

	.user-search {
		display: flex;
		flex-flow: row;
	}

	.user-search>div:last-of-type {
		margin-left: 10px;
	}

	.user-add {
		display: flex;
		flex-flow: row;
		height: 50px;
		align-items: flex-end;
	}

	.user-table {
		margin-top: 10px;
	}

	.user-page {
		margin-top: 20px;
	}
</style>
