<template>
	<div class="login-mian">
		<!-- 主体 -->
		<div class="login-mian-cet">
			<div class="login-text">
				<div class="login-text-one">
					<img src="../../assets/login.png" alt="" style="height: 100px;width: 100px;border-radius: 50%;">
				</div>
			</div>
			<div class="login-title">用户注册后台管理系统</div>
			<div class="login-input">
				<Input prefix="ios-contact" v-model="fromData.username" placeholder="请输入管理员姓名..." size="large"
					style="width:100%;" />
			</div>
			<div class="login-input">
				<Input prefix="ios-key" v-model="fromData.password" type="password" placeholder="请输入管理员密码..." size="large"
					style="width:100%;" />
			</div>
			<div class="login-but" @click="loginCk">
				登陆
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				fromData: {
					username: 'admin',
					password: 'admin',
				}
			}
		},
		methods: {
			loginCk() {
				let data = this.fromData;
				if (data.username == '') {
					this.$Message.info('用户名不能为空');
					return;
				}
				if (data.password == '') {
					this.$Message.info('密码不能为空');
					return;
				}
				if (data.username != 'admin') {
					this.$Message.info('账号不存在');
					return;
				}
				if (data.password != 'admin') {
					this.$Message.info('密码错误');
					return;
				}
				setTimeout(function() {
					this.$Message.info('登录成功');
					this.$router.push({
						path: '/'
					})
				}.bind(this), 1000);
			}
		}
	}
</script>

<style>
	.login-mian {
		display: flex;
		flex-flow: column;
		background: #F5F6F9;
		height: 100vh;
		justify-content: center;
		align-items: center;
	}

	.login-mian-cet {
		display: flex;
		flex-flow: column;
		height: 400px;
		width: 500px;
		background: #fff;
		align-items: center;
		position: relative;
		border-radius: 10px;
		box-shadow: 10px 10px 5px #888888;
	}

	.login-text {
		height: 140px;
		width: 140px;
		background: #144686;
		display: flex;
		flex-flow: row;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		position: absolute;
		top: -70px;
	}

	.login-title {
		font-size: 30px;
		color: #FCC321;
		font-weight: 600px;
		margin-top: 90px;
		margin-bottom: 30px;
	}

	.login-input {
		width: 100%;
		padding: 0px 40px;
		height: 60px;
		background: #FEFDFA;
	}

	.login-text-one {
		background: #fff;
		width: 110px;
		height: 110px;
		display: flex;
		flex-flow: row;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
	}

	.login-but {
		display: flex;
		flex-flow: row;
		align-items: center;
		justify-content: center;
		font-size: 24px;
		color: #fff;
		width: 84%;
		height: 40px;
		background: #FAC002;
		border-radius: 8px;
		margin-top: 10px;
	}
	.login-but:hover{
		cursor:pointer;
	}
</style>
