<template>
	<div id="app">
		<!-- <keep-alive>
			<router-view v-if="$route.meta.keepAlive" />
		</keep-alive>
		<router-view v-if="!$route.meta.keepAlive"></router-view>
		
		<keep-alive>
			<router-view v-if="$route.meta.keepAlive" />
		</keep-alive> -->
		<router-view></router-view>
	</div>
</template>

<script>
	export default {

	}
</script>

<style>
	#app {}
</style>
