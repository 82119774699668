<template>
	<div class="user-mian">
		<!-- 收索 -->
		<div class="user-search">
			<div>
				<Input v-model="searchData.user_name" clearable placeholder="请输入医生名称..." style="width: 300px" />
			</div>
			<div style="margin-left: 10px;">
				<Select placeholder="请选择医生类型..." clearable style="width: 200px" v-model="searchData.doctor_type">
					<Option v-for="item in type" :value="item.value" :key="item.value">{{ item.label }}</Option>
				</Select>
			</div>
			<div>
				<Button type="primary" @click="searchCk">搜索</Button>
			</div>
		</div>
		<!-- 添加按钮 -->
		<div class="user-add">
			<div>
				<Button type="primary" @click="doctorAdd">添加医生</Button>
			</div>
		</div>
		<!-- 表格 -->
		<div class="user-table">
			<Table :columns="columns" border :data="data" width="100rem">
				<template slot-scope="{ row }" slot="action">
					<Button type="primary" size="small" style="margin-right: 5px" @click="dotorsEidt(row)">编辑</Button>
					<Button type="primary" size="small" style="margin-right: 5px"
						@click="mechanismCk(row)">拥有设备</Button>
					<Button type="primary" size="small" style="margin-right: 5px" @click="userCk(row)">签约用户</Button>
					<Button type="error" size="small" style="margin-right: 5px" @click="delShow(row.id)">删除</Button>
				</template>
				<template slot-scope="{ row }" slot="doctor_type">
					<Button type="primary" size="small" style="margin-right: 5px"
						v-if="row.doctor_type == 1">全科</Button>
					<Button type="error" size="small" style="margin-right: 5px" v-if="row.doctor_type == 2">专家</Button>
				</template>
			</Table>
		</div>
		<!-- 分页 -->
		<div class="user-page">
			<Page :total="total" show-elevator :show-total="true" :current="page" @on-change="onChange"
				:page-size="pagesize" />
		</div>
		<!-- 弹出层 -->
		<Modal v-model="doctorPop" width="888px">
			<p slot="header" style="color:#2D8CF0;text-align:center">
				<Icon type="ios-information-circle"></Icon>
				<span>添加医生</span>
			</p>
			<div class="pop">
				<Form :label-width="100">
					<FormItem label="手机号">
						<Input placeholder="请输入手机号" v-model="formData.mobile" />
					</FormItem>
					<FormItem label="名称">
						<Input placeholder="请输入医生名称" v-model="formData.realname" />
					</FormItem>
					<FormItem label="职称">
						<Input placeholder="请输入职位" v-model="formData.title" />
					</FormItem>
					<FormItem label="机构">
						<Select placeholder="请选择机构..." clearable :filterable="true" @on-change="onChageSe"
							v-model="formData.hospital_id">
							<Option v-for="item in equipmentList" :value="item.id" :key="item.id">{{ item.hos_name }}
							</Option>
						</Select>
					</FormItem>
					<FormItem label="根据科类区分">
						<Select placeholder="请选择科室..." clearable :filterable="true" v-model="formData.department_id">
							<Option v-for="item in departmentList" :value="item.id" :key="item.id">{{ item.name }}
							</Option>
						</Select>
					</FormItem>
					<FormItem label="类型">
						<Select placeholder="请选择医生类型..." clearable v-model="formData.doctor_type">
							<Option v-for="item in type" :value="item.value" :key="item.value">{{ item.label }}</Option>
						</Select>
					</FormItem>
					<FormItem label="头像">
						<Upload action="//jsonplaceholder.typicode.com/posts/">
							<Button icon="ios-cloud-upload-outline">上传医生头像</Button>
						</Upload>
					</FormItem>
					<FormItem label="性别">
						<RadioGroup v-model="formData.gender">
							<Radio label="0">男</Radio>
							<Radio label="1">女</Radio>
							<Radio label="2">未知</Radio>
						</RadioGroup>
					</FormItem>
				</Form>
			</div>
			<div slot="footer">
				<Button type="error" size="small" @click="doctorPop = false">取消</Button>
				<Button type="primary" size="small" @click="addDoctor">确认</Button>
			</div>
		</Modal>


		<!-- 编辑弹出层 -->
		<Modal v-model="doctorEidtPop" width="888px">
			<p slot="header" style="color:#ED4014;text-align:center">
				<Icon type="ios-information-circle"></Icon>
				<span>编辑医生</span>
			</p>
			<div class="pop">
				<Form :label-width="100">
					<FormItem label="手机号">
						<Input placeholder="请输入手机号" v-model="formEidtData.mobile" />
					</FormItem>
					<FormItem label="名称">
						<Input placeholder="请输入医生名称" v-model="formEidtData.realname" />
					</FormItem>
					<FormItem label="职称">
						<Input placeholder="请输入职位" v-model="formEidtData.title" />
					</FormItem>
					<FormItem label="机构">
						<Select placeholder="请选择机构..." clearable :filterable="true" @on-change="onChageSe"
							v-model="formEidtData.hospital_id">
							<Option v-for="item in equipmentList" :value="item.id" :key="item.id">{{ item.hos_name }}
							</Option>
						</Select>
					</FormItem>
					<FormItem label="根据科类区分">
						<Select placeholder="请选择科室..." clearable :filterable="true"
							v-model="formEidtData.department_id">
							<Option v-for="item in departmentList" :value="item.id" :key="item.id">{{ item.name }}
							</Option>
						</Select>
					</FormItem>
					<FormItem label="类型">
						<Select placeholder="请选择医生类型..." clearable v-model="formEidtData.doctor_type">
							<Option v-for="item in type" :value="item.value" :key="item.value">{{ item.label }}</Option>
						</Select>
					</FormItem>
					<FormItem label="头像">
						<Upload action="//jsonplaceholder.typicode.com/posts/">
							<Button icon="ios-cloud-upload-outline">上传医生头像</Button>
						</Upload>
					</FormItem>
					<FormItem label="性别">
						<RadioGroup v-model="formEidtData.gender">
							<Radio label="male">男</Radio>
							<Radio label="female">女</Radio>
							<Radio label="unknow">未知</Radio>
						</RadioGroup>
					</FormItem>
				</Form>
			</div>
			<div slot="footer">
				<Button type="error" size="small" @click="doctorEidtPop = false">取消</Button>
				<Button type="primary" size="small" @click="dotorsEidtSand">确认</Button>
			</div>
		</Modal>

		<!-- 弹出层 -->
		<Modal v-model="delPop" width="444px">
			<p slot="header" style="color:#ED4014;text-align:center">
				<Icon type="ios-information-circle"></Icon>
				<span>删除用户</span>
			</p>
			<div class="pop">
				<div class="del">确定要删除此医生吗?</div>
			</div>
			<div slot="footer">
				<Button type="error" size="small" @click="delPop = false">取消</Button>
				<Button type="primary" size="small" @click="delSave()">确认</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	export default {
		created() {
			this.doctorsList();
		},
		data() {
			return {
				doctorEidtPop: false,
				// 职位称号
				titleList: [{
						value: 1,
						label: "主任"
					},
					{
						value: 2,
						label: "副主任"
					},
				],
				type: [{
						value: 1,
						label: "全科医生"
					},
					{
						value: 2,
						label: "专家"
					},
				],
				total: 0,
				page: 1, //当前码
				pagesize: 8, //每页显示多少条
				doctorPop: false,
				delPop: false,
				userid: "",
				searchData: {
					user_name: "",
					doctor_type: "",
				},
				formData: {
					mobile: '',
					realname: '',
					title: '',
					hospital_id: '',
					department_id: '',
					doctor_type: '',
					gender: ''
				},
				formEidtData: { //编辑
					mobile: '',
					realname: '',
					title: '',
					hospital_id: '',
					department_id: '',
					doctor_type: '',
					gender: ''
				},
				equipmentList: [],
				departmentList: [],
				columns: [{
						title: '编号',
						key: 'id',
					},
					{
						title: '医生名称',
						key: 'realname',
					},
					{
						title: '手机号',
						key: 'mobile',
					},
					{
						title: '所属机构',
						key: 'hos_name',
						width: 200,
					},
					{
						title: '职位',
						key: 'title',
					},
					{
						title: '科室',
						key: 'name',
					},
					{
						title: '类型',
						key: 'doctor_type',
						slot: 'doctor_type',
					},
					{
						title: '操作',
						slot: 'action',
						width: 300,
						align: 'center',
					}
				],
				data: []
			}
		},
		methods: {
			// 分页页码获取
			onChange: function(currentPage) {
				this.page = currentPage;
				this.doctorsList();
			},
			//搜索接口
			searchCk() {
				this.page = 1;
				this.doctorsList();
			},
			// 获取医生列表
			doctorsList() {
				let data = {
					page: this.page,
					pagesize: this.pagesize,
					realname: this.searchData.user_name,
					doctor_type: this.searchData.doctor_type
				};
				let url = this.httpsUrl + 'doctorController/doctorList';
				this.$http.post(url, data, true).then(result => {
					console.log(result.data.data)
					this.data = result.data.data
					this.total = result.data.count
				});
			},
			//添加医生
			doctorAdd() {
				this.doctorPop = true;
				let data = {};
				let url = this.httpsUrl + 'doctorController/doctorMechanismList';
				this.$http.post(url, data, true).then(result => {
					console.log(result.data)
					this.equipmentList = result.data
				});
			},
			// 添加医生发送
			addDoctor() {
				if (this.formData.mobile.length != 11) {
					this.$Message.info('手机号不能小于11位');
					return;
				}
				if (this.formData.realname == '') {
					this.$Message.info('医生名称不能为空');
					return;
				}
				if (this.formData.title == '') {
					this.$Message.info('职位必选');
					return;
				}
				if (this.formData.hospital_id == '') {
					this.$Message.info('请选择机构');
					return;
				}
				if (this.formData.gender == '') {
					this.$Message.info('请选择性别');
					return;
				}
				let data = this.formData;
				let url = this.httpsUrl + 'doctorController/addDoctorSand';
				this.$http.post(url, data, true).then(result => {
					if (result.data.code == 200) {
						this.$Message.info(result.data.msg);
						this.doctorPop = false;
						this.doctorsList();
						return;
					}
					this.$Message.info(result.data.msg);
				});
			},
			//获取科室
			onChageSe(e) {
				let data = {
					id: e
				};
				let url = this.httpsUrl + 'doctorController/doctorDepartment';
				this.$http.post(url, data, true).then(result => {
					console.log(result.data)
					this.departmentList = result.data
				});
			},
			// 编辑操作
			dotorsEidt(e) {
				this.doctorEidtPop = true
				let data = {
					id: e.id
				};
				let url = this.httpsUrl + 'doctorController/doctorFind';
				this.$http.post(url, data, true).then(result => {
					console.log(result.data.data)
					this.formEidtData = result.data.data.res
					this.equipmentList = result.data.data.equipmentList
					this.departmentList = result.data.data.departmentList
				});
			},
			dotorsEidtSand() {
				if (this.formEidtData.mobile.length > 11) {
					this.$Message.info('手机号不能小于11位');
					return;
				}
				if (this.formEidtData.realname == '') {
					this.$Message.info('医生名称不能为空');
					return;
				}
				if (this.formEidtData.title == '') {
					this.$Message.info('职位必选');
					return;
				}
				if (this.formEidtData.hospital_id == '') {
					this.$Message.info('请选择机构');
					return;
				}
				if (this.formEidtData.gender == '') {
					this.$Message.info('请选择性别');
					return;
				}
				let data = this.formEidtData;
				let url = this.httpsUrl + 'doctorController/dotorsEidtSand';
				this.$http.post(url, data, true).then(result => {
					if (result.data.code == 200) {
						this.$Message.info(result.data.msg);
						this.doctorEidtPop = false;
						this.doctorsList();
						return;
					}
					this.$Message.info(result.data.msg);
				});
			},
			//跳转医生设备列表
			mechanismCk(row) {
				this.$router.push({
					path: '/doctors_mechanism',
					query: {
						id: row.id,
						name: row.realname
					}
				})
			},
			//查看签约用户
			userCk(row) {
				this.$router.push({
					path: '/doctors_user',
					query: {
						id: row.id,
						name: row.realname
					},
				})
			},
			// 删除用户
			delShow(id) {
				this.delPop = true;
				this.userid = id;
			},
			delSave() {
				let data = {
					id: this.userid
				}
				let url = this.httpsUrl + 'doctorController/delSaveUser';
				this.$http.post(url, data, true).then(result => {
					if (result.data.code == 200) {
						this.$Message.info(result.data.msg);
						this.delPop = false;
						this.doctorsList();
						return;
					}
					this.$Message.info(result.data.msg);
				});
			}
		}
	}
</script>

<style>
	.user-mian {
		display: flex;
		flex-flow: column nowrap;
		background: #fff;
		padding: 20px 10px;
		border-radius: 10px;
	}

	.user-search {
		display: flex;
		flex-flow: row;
	}

	.user-search>div:last-of-type {
		margin-left: 10px;
	}

	.user-add {
		display: flex;
		flex-flow: row;
		height: 50px;
		align-items: flex-end;
	}

	.user-table {
		margin-top: 10px;
	}

	.user-page {
		margin-top: 20px;
	}
</style>
