<template>
	<div class="user-mian">
		<!-- 收索 -->
		<div class="user-search">
			<div>
				<Input v-model="searchData.user_name" placeholder="请输入机构名称..." clearable style="width: 300px" />
			</div>
			<div>
				<Button type="primary" @click="searchCk">搜索</Button>
			</div>
		</div>
		<!-- 添加按钮 -->
		<div class="user-add">
			<div>
				<Button type="primary" @click="adduser">添加机构排班</Button>
			</div>
		</div>
		<!-- 表格 -->
		<div class="user-table">
			<Table :columns="columns" border :data="data">
				<template slot-scope="{ row }" slot="action">
					<Button type="primary" size="small" style="margin-right: 5px"
						@click="EidtUserck(row.id)">审核机构排班</Button>
					<Button type="primary" size="small" style="margin-right: 5px"
						@click="mechanismCk(row)">编辑排班</Button>
					<Button type="error" size="small" style="margin-right: 5px" @click="delShow(row.id)">删除</Button>
				</template>
				<template slot-scope="{ row }" slot="gender">
					<Button type="primary" size="small" style="margin-right: 5px" v-if="row.gender == 1">男</Button>
					<Button type="error" size="small" style="margin-right: 5px" v-if="row.gender == 2">女</Button>
				</template>
			</Table>
		</div>
		<!-- 分页 -->
		<div class="user-page">
			<Page :total="total" show-elevator :show-total="true" :current="page" @on-change="onChange"
				:page-size="pagesize" />
		</div>
		<!-- 弹出层 -->
		<Modal v-model="userPop" width="888px">
			<p slot="header" style="color:#2D8CF0;text-align:center">
				<Icon type="ios-information-circle"></Icon>
				<span>添加排班</span>
			</p>
			<div class="pop">
				<Form :label-width="100">
					<FormItem label="选择机构">
						<Select placeholder="请选择机构..." clearable :filterable="true" v-model="formData.hospital_id">
							<Option v-for="item in equipmentList" :value="item.id" :key="item.id">{{ item.hos_name }}
							</Option>
						</Select>
					</FormItem>
					<div class="paiba">
						<FormItem label="开始日期">
							<Col span="12">
							<DatePicker type="date" placeholder="Select date" @on-change="setStartTime"
								format="yyyy-MM-dd"  style="width: 200px"></DatePicker>
							</Col>
						</FormItem>
						<FormItem label="结束日期">
							<Col span="12">
							<DatePicker type="date" placeholder="Select date"  @on-change="setEndTime" style="width: 200px"></DatePicker>
							</Col>
						</FormItem>
					</div>
				</Form>
			</div>
			<div slot="footer">
				<Button type="error" size="small" @click="userPop = false">取消</Button>
				<Button type="primary" size="small" @click="addSand()">确认</Button>
			</div>
		</Modal>
		<!-- 弹出层 -->
		<Modal v-model="EiatPop" width="888px">
			<p slot="header" style="color:#ED4014;text-align:center">
				<Icon type="ios-information-circle"></Icon>
				<span>编辑用户</span>
			</p>
			<div class="pop">
				<Form :label-width="100">
					<FormItem label="手机号">
						<Input placeholder="请输入手机号" v-model="formEidtData.mobile" />
					</FormItem>
					<FormItem label="名称">
						<Input placeholder="请输入用户名称" v-model="formEidtData.realname" />
					</FormItem>
					<FormItem label="性别">
						<RadioGroup v-model="formEidtData.gender">
							<Radio :label="1">男</Radio>
							<Radio :label="2">女</Radio>
						</RadioGroup>
					</FormItem>
				</Form>
			</div>
			<div slot="footer">
				<Button type="error" size="small" @click="EiatPop = false">取消</Button>
				<Button type="primary" size="small" @click="eidtSand()">确认</Button>
			</div>
		</Modal>
		<!-- 弹出层 -->
		<Modal v-model="delPop" width="444px">
			<p slot="header" style="color:#ED4014;text-align:center">
				<Icon type="ios-information-circle"></Icon>
				<span>删除用户</span>
			</p>
			<div class="pop">
				<div class="del">确定要删除此用户吗?</div>
			</div>
			<div slot="footer">
				<Button type="error" size="small" @click="delPop = false">取消</Button>
				<Button type="primary" size="small" @click="delSave()">确认</Button>
			</div>
		</Modal>
	</div>
</template>
<script>
	export default {
		created() {
			this.userList();
		},
		data() {
			return {
				total: 0,
				userPop: false,
				EiatPop: false,
				delPop: false,
				userid: "",
				page: 1, //当前码
				pagesize: 8, //每页显示多少条
				searchData: { //搜索
					user_name: ""
				},
				equipmentList: [],
				formData: {
					hospital_id: "",
					starting_time: "", //开始时间
					end_time: "", //结束时间
				},
				formEidtData: {
					realname: "",
					gender: "",
					mobile: "",
					id: "",
				},
				columns: [{
						title: '机构排班编号',
						key: 'id'
					},
					{
						title: '机构名称',
						key: 'hos_name'
					},
					{
						title: '开始时间段',
						key: 'starting_time',
					},
					{
						title: '结束时间段',
						key: 'end_time'
					},
					{
						title: '排班发布日期',
						key: 'create_time'
					},
					{
						title: '操作',
						slot: 'action',
						width: 400,
						align: 'center'
					}
				],
				data: []
			}
		},
		methods: {
			userList() {
				let data = {
					page: this.page,
					pagesize: this.pagesize,
					realname: this.searchData.user_name
				};
				let url = this.httpsUrl + 'RosterController/mechanismScheduleList';
				this.$http.post(url, data, true).then(result => {
					console.log(result.data.data)
					this.data = result.data.data
					this.total = result.data.count
				});
			},
			// 分页页码获取
			onChange: function(currentPage) {
				this.page = currentPage;
				this.userList();
			},
			//搜索接口
			searchCk() {
				this.page = 1;
				this.userList();
			},
			//添加排班
			adduser() {
				this.userPop = true;
				let data = {};
				let url = this.httpsUrl + 'RosterController/rosterMechanismList';
				this.$http.post(url, data, true).then(result => {
					this.equipmentList = result.data
				});
			},
			// 添加排班
			addSand() {
				console.log(this.formData);
				if (this.formData.hospital_id == '') {
					this.$Message.info('请选择机构');
					return;
				}
				if (this.formData.starting_time == '') {
					this.$Message.info('请选择开始时间');
					return;
				}
				if (this.formData.end_time == '') {
					this.$Message.info('请选择结束时间');
					return;
				}
				let data = this.formData;
				let url = this.httpsUrl + 'RosterController/addRoster';
				this.$http.post(url, data, true).then(result => {
					if (result.data.code == 200) {
						this.$Message.info(result.data.msg);
						this.userPop = false;
						this.userList();
						return;
					}
					this.$Message.info(result.data.msg);
				});
			},
			//编辑排班
			EidtUserck(id) {
				this.EiatPop = true;
				let data = {
					id: id
				}
				let url = this.httpsUrl + 'UserController/userEidtFind';
				this.$http.post(url, data, true).then(result => {
					this.formEidtData = result.data.data
				});
			},
			//发送修改数据
			eidtSand() {
				let data = this.formEidtData
				let url = this.httpsUrl + 'UserController/userEidtSave';
				this.$http.post(url, data, true).then(result => {
					if (result.data.code == 200) {
						this.$Message.info(result.data.msg);
						this.EiatPop = false;
						this.userList();
						return;
					}
					this.$Message.info(result.data.msg);
				});
			},
			// 删除排班
			delShow(id) {
				this.delPop = true;
				this.userid = id;
			},
			delSave() {
				let data = {
					id: this.userid
				}
				let url = this.httpsUrl + 'UserController/delSaveUser';
				this.$http.post(url, data, true).then(result => {
					if (result.data.code == 200) {
						this.$Message.info(result.data.msg);
						this.delPop = false;
						this.userList();
						return;
					}
					this.$Message.info(result.data.msg);
				});
			},
			setStartTime(e) {
				this.formData.starting_time = e;
			},
			setEndTime(e){
				this.formData.end_time = e;
			}
		}
	}
</script>

<style>
	.user-mian {
		display: flex;
		flex-flow: column nowrap;
		background: #fff;
		padding: 20px 10px;
		border-radius: 10px;
	}

	.user-search {
		display: flex;
		flex-flow: row;
	}

	.user-search>div:last-of-type {
		margin-left: 10px;
	}

	.user-add {
		display: flex;
		flex-flow: row;
		height: 50px;
		align-items: flex-end;
	}

	.user-table {
		margin-top: 10px;
	}

	.user-page {
		margin-top: 20px;
	}

	.del {
		height: 50px;
		display: flex;
		flex-flow: row;
		align-items: center;
		justify-content: center;
		font-size: 30px;
	}

	/* //排班 */
	.paiba {
		display: flex;
		flex-flow: row;

	}
</style>
