import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/index/index.vue";
import Home from "../views/index/home.vue";
// 登录管理
import Login from "../views/login/index.vue";
// 医生管理
import doctorsList from "../views/doctors/doctors_list.vue";
import doctorsMechanism from "../views/doctors/doctors_mechanism.vue"; //拥有设备
import doctorsUser from "../views/doctors/doctors_user.vue"; //签约用户
// 机构管理
import equipmentList from "../views/equipment/equipment_list.vue";
import cooperationList from "../views/equipment/cooperation_list.vue"; //合作机构
import chiequipmentList from "../views/equipment/childEquipment_list.vue"; //子级机构
import equipmentMechanism from "../views/equipment/equipment_mechanism.vue"; //合作机构
//排班管理
import doctorSchedule  from "../views/roster/doctor_schedule.vue"; //医生排班
import mechanismSchedule from "../views/roster/mechanism_schedule.vue"; //合作机构
//用户管理
import userList from "../views/user/user_list.vue";
import userMechanism from "../views/user/user_mechanism.vue"; //拥有设备
import userDoctors from "../views/user/user_doctors.vue"; //签约医生
import userMember from "../views/user/user_member.vue"; //家庭成员
//设备管理
import mechanismList from "../views/mechanism/mechanism_list.vue";
// 小程序分发管理后台
import appletsDoctro from "../views/applets/applets_doctro.vue" //医生通知
import appletsUser from "../views/applets/applets_user.vue" //户主通知
Vue.use(VueRouter);

const routes = [
	// 登陆页面
	{
		path: '/login',
		name: '登陆',
		component: Login, //登陆页面
	},
	{
		path: "/",
		name: "Index",
		component: Index,
		children: [
			// 欢迎页
			{
				path: '',
				name: '主页',
				component: Home, //欢迎页面
			},
			// 医生管理
			{
				path: '/doctors_list',
				name: '医生列表',
				component: doctorsList,
				// meta: {
				// 	keepAlive: true // 需要被缓存
				// }
			},
			{
				path: '/doctors_mechanism',
				name: '医生设备详情',
				component: doctorsMechanism,
			},
			{
				path: '/doctors_user',
				name: '医生签约用户',
				component: doctorsUser,
			},
			// 机构管理
			{
				path: '/equipment_list',
				name: '机构列表',
				component: equipmentList,
				// meta: {
				// 	keepAlive: true // 需要被缓存
				// }
			},
			{
				path: '/cooperation_list',
				name: '合作机构',
				component: cooperationList,
			},
			{
				path: '/childEquipment_list',
				name: '子级机构',
				component: chiequipmentList,
			},
			{
				path: '/equipment_mechanism',
				name: '机构设备详情',
				component: equipmentMechanism,
			},
			//排版管理
			{
				path: '/doctor_schedule',
				name: '医生排班',
				component: doctorSchedule,
			},
			{
				path: '/mechanism_schedule',
				name: '机构设备详情',
				component: mechanismSchedule,
			},
			// 用户管理
			{
				path: '/user_list',
				name: '用户列表',
				component: userList,
				// meta: {
				// 	keepAlive: true // 需要被缓存
				// }
			},
			{
				path: '/user_mechanism',
				name: '设备详情',
				component: userMechanism,
			},
			{
				path: '/user_doctors',
				name: '签约医生',
				component: userDoctors,
			},
			{
				path: '/user_member',
				name: '家庭成员',
				component: userMember,
			},
			// 设备管理
			{
				path: '/mechanism_list',
				name: '设备列表',
				component: mechanismList,
			},
			// 小程序
			{
				path: '/applets_doctro',
				name: '小程序医生信息',
				component: appletsDoctro,
			},
			{
				path: '/applets_user',
				name: '小程序用户信息',
				component: appletsUser,
			},
		]
	}
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes
});

export default router;
